import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  private store: any = {};
  constructor() {}

  public setStore(type: string, data: any) {
    this.store[type] = data;
    // this.storeChanged();
  }

  public getStore(type: string) {
    return this.store[type];
  }

  public clear() {
    this.store = {};
  }

  storeChanged() {
      console.log('+++++++++++++++');
      console.log('store changed');
      console.log(this.store);
      console.log('+++++++++++++++');
  }
}
