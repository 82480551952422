import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DxMapModule, DxCheckBoxModule, DxButtonModule } from 'devextreme-angular';
import { MapRoutingModule } from './map-routing.module';
import { MapComponent } from './map/map.component';

@NgModule({
  declarations: [MapComponent],
  imports: [
    CommonModule,
    MapRoutingModule,
    DxMapModule,
    DxCheckBoxModule,
    DxButtonModule
  ]
})
export class MapModule { }
