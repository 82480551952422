import { Component, OnInit } from '@angular/core';
import { IDevice } from '../device-header-list/device-interface';
import { DeviceStoreService } from 'src/app/store/devices/device-store.service';

@Component({
  selector: 'app-alert-header-list',
  templateUrl: './alert-header-list.component.html',
  styleUrls: ['./alert-header-list.component.scss']
})
export class AlertHeaderListComponent implements OnInit {
  selectedDevice: IDevice;
  constructor(private deviceStore: DeviceStoreService) { }

  ngOnInit() {
    this.watchForSelectedDeviceChanges();
  }

  public watchForSelectedDeviceChanges() {
    console.log('watching');
    this.deviceStore.getSelectedDeviceChanged().subscribe(res => {
      console.log(res);
    });
  }

}
