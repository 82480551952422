import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  deviceChangeFunction = new EventEmitter();
  subsVar: Subscription;

  constructor() { }

  onDeviceChangeButtonClick() {
    this.deviceChangeFunction.emit();
  }

}
