import { Injectable } from '@angular/core';
import { BaseApiService } from 'src/app/base-api.service';
import { UserStoreService } from 'src/app/store/dispatch/user.store.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseApiService {

  constructor(private userStore: UserStoreService, private http: HttpClient) {
    super();
   }

  public loadUser(id: number) {
    const headers = this.userStore.getHeaders();
    const query = `userId=${id}`;
    return this.http.get(`${this.API_URL}/users?${query}`, { headers });
  }

  updateUser(user: any): Observable<any> {
    const headers = this.userStore.getHeaders();
    const userstore = this.userStore.getUser();
    const { id } = userstore;

    const updated = Object.assign(userstore, user);

    return this.http.put(`${this.API_URL}/users/${id}`, updated, { headers });
  }
}
