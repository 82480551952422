import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DxChartModule, DxSelectBoxModule } from 'devextreme-angular';

@NgModule({
  imports: [
    CommonModule,
    DashboardRoutingModule,
    DxChartModule,
    DxSelectBoxModule
  ],
  declarations: [DashboardComponent],
  bootstrap: [DashboardComponent],
 
})
export class DashboardModule { }

