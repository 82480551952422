import { Component, OnInit } from '@angular/core';
import { IDevice } from './device-interface';
import { DeviceStoreService } from 'src/app/store/devices/device-store.service';
import { EventEmitterService } from 'src/app/event-emitter.service';

@Component({
  selector: 'app-device-header-list',
  templateUrl: './device-header-list.component.html',
  styleUrls: ['./device-header-list.component.scss'],
  providers: [DeviceStoreService]
})

export class DeviceHeaderListComponent implements OnInit {
  selectedDevice: IDevice;
  devices: Array<IDevice> = [];
  loading = false;
  constructor(private deviceStore: DeviceStoreService,
              private eventEmitterService: EventEmitterService
    ) {}

  ngOnInit() {
    this.loadDevices();
  }

  private loadDevices() {
    this.loading = true;
    this.deviceStore.getDevices().then(res => {
      this.devices = res;
      if (this.devices && this.devices.length) {
        this.setSelectedDevice(this.devices[0]);
      }
      this.loading = false;
    });
  }

  public setSelectedDevice(device: IDevice) {
    this.deviceStore.setSelectedDevice(device);
    this.selectedDevice = device;
    this.eventEmitterService.onDeviceChangeButtonClick();
  }

}
