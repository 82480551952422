import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

import { UserStoreService } from '../store/dispatch/user.store.service';
import { BaseApiService } from '../base-api.service';
import { Observable } from 'rxjs';


export interface Position {
  id: number;
  deviceId: number;
  protocol: string;
  deviceTime: Date;
  fixTime: Date;
  serverTime: Date;
  outdated: boolean;
  valid: boolean;
  latitude: number;
  longitude: number;
  altitude: number;
  speed: number;
  course: number;
  address: string;
  accuracy: number;
  network: string;
  attributes: Attributes;
}

export interface Attributes {
  battery: number;
  batteryLevel: number;
  d1: number;
  d2: number;
  d3: number;
  distance: number;
  exte: number;
  inte: number;
  humidity: number;
  motion: boolean;
  totalDistance: number;
  w1: number;
  w2: number;
  w3: number;
  date: Date;
}

export interface Weight {
  w1: number;
  w2: number;
  w3: number;
  d1: number;
  d2: number;
  d3: number;
  int: number;
  ext: number;
  humi: number;
  date?: Date;
}


@Injectable({
  providedIn: 'root'
})
export class HistoryService extends BaseApiService {
  constructor(private userStore: UserStoreService, private http: HttpClient) {
    super();
  }

  public search(data: any) {
    const headers = this.userStore.getHeaders();
    const from = moment(data.from).toISOString();
    const to = moment(data.to).toISOString();
    const query = `deviceId=${data.device}&from=${from}&to=${to}`;
    return this.http.get(`${this.API_URL}/positions?${query}`, { headers });
  }

  public graphData(data: any): Observable<Position[]> {
    const headers = this.userStore.getHeaders();
    const from = moment(data.from).toISOString();
    const to = moment(data.to).toISOString();
    const query = `deviceId=${data.device}&from=${from}&to=${to}`;
    return this.http.get<Position[]>(`${this.API_URL}/positions?${query}`, { headers }).pipe();
  }
}
