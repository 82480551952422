import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from 'src/app/base-api.service';
import { UserStoreService } from '../dispatch/user.store.service';
import { StoreService } from '../store.service';
import { IDevice } from 'src/app/shared/device-header-list/device-interface';

@Injectable({
  providedIn: 'root'
})
export class DeviceStoreService extends BaseApiService {
  public selectedDeviceChanged: Subject<IDevice> = new Subject();
  constructor(public http: HttpClient, private userStore: UserStoreService, private store: StoreService) {
    super();
  }

  public getSelectedDeviceChanged(): Observable<IDevice> {
    return this.selectedDeviceChanged;
  }

  public setDevices(devices: Array<IDevice>) {
    devices.sort((a, b) => a.name.localeCompare(b.name))
    this.store.setStore('devices', devices);
  }

  public getAreDevicesLoaded() {
    return this.store.getStore('devices_loaded');
  }

  public setAreDevicesLoaded(data: boolean) {
    this.store.setStore('devices_loaded', data);
  }

  public setSelectedDevice(device: IDevice) {
    this.store.setStore('selected_device', device);
    this.selectedDeviceChanged.next(device);
  }

  public getSelectedDevice(): IDevice {
    return this.store.getStore('selected_device');
  }

  public getDevices(): Promise<Array<IDevice>> {
    return new Promise((resolve, reject) => {
      if (this.getAreDevicesLoaded()) {
        resolve(this.store.getStore('devices'));
      } else {
        this.getDevicesFromAPI().subscribe(res => {
          this.setDevices(res);
          this.setAreDevicesLoaded(true);
          resolve(this.store.getStore('devices'));
        }, err => {
          reject(err);
          this.setAreDevicesLoaded(false);
        });
      }
    });
  }

  private getDevicesFromAPI(): Observable<any> {
    const headers = this.userStore.getHeaders();

    return this.http.get(`${this.API_URL}/devices`, {headers});
  }
}
