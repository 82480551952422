import { DashboardService, Population, Position, Weight, Attributes } from './../dashboard.service';
import { DeviceStoreService } from 'src/app/store/devices/device-store.service';
import { Component, OnInit } from '@angular/core';
import { Moment } from 'moment';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { EventEmitterService } from 'src/app/event-emitter.service';
import { IDevice } from 'src/app/shared/device-header-list/device-interface';
import { stringify } from '@angular/core/src/util';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DashboardService],
  preserveWhitespaces: true
})
export class DashboardComponent implements OnInit {

  //populationData: Population[];
  positionData: Position[];
  weightData: Weight[] = [];
  types: string[] = ['area', 'stackedarea', 'fullstackedarea'];
  public loading = false;
  history: Array<any> = [];
  latestData: Array<any> = [];
  selectedDeviceData: Attributes = {
    w1: 0,
    w2: 0,
    w3: 0,
    d1: 0,
    d2: 0,
    d3: 0,
    battery: 0,
    batteryLevel: 0,
    distance: 0,
    exte: 0,
    inte: 0,
    fe: 0,
    humidity: 0,
    motion: false,
    rssi: 0,
    totalDistance: 0,
    date: null
  };
  //totalHistoryItems = 0;
  animation = true;
  interval: string;

  showWeight1= false;
  showWeight2= false;
  showWeight3= false;
  graphDateRange : number;
  selected: {startDate: Moment, endDate: Moment};
  selectedDeviceName: string;


  constructor(private dashboardService: DashboardService,
              private deviceStore: DeviceStoreService,
              private eventEmitterService: EventEmitterService
              ) {
                
                this.graphDateRange=7; //default value

                setTimeout(() => {
                  if(this.loading==false)
                  {
                    this.getLatestData();
                    this.getGraphData( this.graphDateRange);
                  }
                 }, 100);

                //if (this.eventEmitterService.subsVar == undefined) {
                this.eventEmitterService.subsVar = this.eventEmitterService.deviceChangeFunction.subscribe(() => {
                      this.getLatestData();
                      this.getGraphData( this.graphDateRange);
                    });
                  //}

  }


  legendClickHandler (e) {
    var series = e.target;
    if (series.isVisible()) {
        series.hide();
    } else {
        series.show();
    }
}


  ngOnInit() {
  }

  graphRange(selectedDays : number)
  {
    this.graphDateRange=selectedDays; 
    this.getGraphData(selectedDays);
  }

  public getLatestData() {

    this.loading = true;


    this.dashboardService.latestData().subscribe((res) => {
      this.latestData = res;

      console.log("latestData" + JSON.stringify(res));
      const tempid = this.deviceStore.getSelectedDevice();

      if(tempid==null)
        return;

        this.showWeight1=false;
        this.showWeight2=false;
        this.showWeight3=false;
      
      const tempdata: Attributes = {
        w1: 0,
        w2: 0,
        w3: 0,
        d1: 0,
        d2: 0,
        d3: 0,
        battery: 0,
        batteryLevel: 0,
        distance: 0,
        exte: 0,
        inte: 0,
        fe: 0,
        humidity: 0,
        motion: false,
        totalDistance: 0,
        rssi: 0,
        date: null
      };

      console.log('Selected device:'  + tempid.id + " "+tempid.name);
      this.selectedDeviceName = tempid.name;
      for (const temp of res) {
        if (temp.deviceId == tempid.id) {
          tempdata.w1 = parseFloat((temp.attributes.w1 / 1000).toFixed(3));
          tempdata.w2 = parseFloat((temp.attributes.w2 / 1000).toFixed(3));
          tempdata.w3 = parseFloat((temp.attributes.w3 / 1000).toFixed(3));
          tempdata.d1 = temp.attributes.d1;
          tempdata.d2 = temp.attributes.d2;
          tempdata.d3 = temp.attributes.d3;
          tempdata.exte = parseFloat(temp.attributes.exte.toFixed(1));
          tempdata.inte = parseFloat(temp.attributes.inte.toFixed(1));
          tempdata.rssi = temp.attributes.rssi;
          if (tempdata.inte>200)
           tempdata.inte=null;
          if (tempdata.exte>200)
           tempdata.exte=null;

          tempdata.humidity = parseFloat((temp.attributes.humidity*10).toFixed(1));
          tempdata.battery = parseFloat((temp.attributes.battery / 1000).toFixed(2));
          tempdata.date = temp.deviceTime;

          //for show/hide of weights (if at least one weigh is > than zero then show weight)
          if(tempdata.w1>0)
            this.showWeight1=true;
          if(tempdata.w2>0)
            this.showWeight2=true;
          if(tempdata.w3>0)
            this.showWeight3=true;
        }
      }

      this.loading = false;
      this.selectedDeviceData = tempdata;
      // this.dataSource.data = this.history;
      console.log('Selected device data:' + JSON.stringify(this.selectedDeviceData));
    });
  }

  public getGraphData(days : number) {

    this.loading = true;
    const tempid = this.deviceStore.getSelectedDevice();

    if(tempid==null)
      return;

    //console.log(this.deviceStore.getSelectedDevice());
    this.weightData=[];
    const data = {
        from: moment().add(-days, 'days'),
        to: moment(),
        device: tempid.id,
        // device: this.deviceStore.getSelectedDevice()
      };

    this.dashboardService.graphData(data).subscribe((res) => {
        this.history = res;
        this.history.forEach((item, i) => {
          item.rowIndex = i;
        });
        console.log(this.history);
       // this.totalHistoryItems = this.history.length;


        for (const temp of res) {
          const tempweight: Weight = {
            w1: 0,
            w2: 0,
            w3: 0,
            d1: 0,
            d2: 0,
            d3: 0,
            int: 0,
            ext: 0,
            humi: 0,
            date: null
          };

          tempweight.w1 = Number((temp.attributes.w1 / 1000).toFixed(3));
          tempweight.w2 = Number((temp.attributes.w2 / 1000).toFixed(3));
          tempweight.w3 = Number((temp.attributes.w3 / 1000).toFixed(3));
          tempweight.d1 = temp.attributes.d1;
          tempweight.d2 = temp.attributes.d2;
          tempweight.d3 = temp.attributes.d3;
          tempweight.int = temp.attributes.inte;
          tempweight.ext = temp.attributes.exte;
          tempweight.humi = temp.attributes.humidity*10;
          tempweight.date = temp.deviceTime;

          if (tempweight.int>200)
          tempweight.int=null;
          if (tempweight.ext>200)
          tempweight.ext=null;
         // var theDate = new Date(Date.parse(
          //  temp.deviceTime.toString()));

    
         // tempweight.date = theDate;

          this.weightData.push(tempweight);

          //for show/hide of weights (if at least one weigh is > than zero then show weight)
          if(tempweight.w1>0)
            this.showWeight1=true;
          if(tempweight.w2>0)
            this.showWeight2=true;
          if(tempweight.w3>0)
            this.showWeight3=true;
        }


        this.loading = false;
        // this.dataSource.data = this.history;
        console.log(this.weightData);
      });

  }


  private parseDate(date: Date) {
    const tmp = new Date(date);

    if (this.interval !== 'hour') {
      return new Date(tmp.setHours(0, 0, 0));
    } else {
      return tmp;
    }
  }


}
