import { Marker, MapService, MyPosition } from './../map.service';
import { Component, OnInit } from '@angular/core';
import { DxMapModule, DxCheckBoxModule, DxButtonModule } from 'devextreme-angular';
import { DeviceStoreService } from 'src/app/store/devices/device-store.service';
import { ToastrService } from 'ngx-toastr';
import { EventEmitterService } from 'src/app/event-emitter.service';
import * as moment from 'moment/moment';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  customMarkerUrl: string;
  mapMarkerUrl: string;
  originalMarkers: Marker[];
  markers: Marker[];
  latestData: Array<any> = [];
  selectedDeviceData: MyPosition;
  public loading = false;

  constructor(private mapservice: MapService,
              private deviceStore: DeviceStoreService,
              private toastr: ToastrService,
              private eventEmitterService: EventEmitterService
    ) {

      setTimeout(() => {
        this.getLatestData();
       }, 3000);

      this.eventEmitterService.subsVar = this.eventEmitterService.deviceChangeFunction.subscribe(() => {
            this.getLatestData();
          });

      this.customMarkerUrl = this.mapMarkerUrl = mapservice.getMarkerUrl();
      this.originalMarkers = this.markers = mapservice.getMarkers();
  }
  checkCustomMarker(data) {
      this.mapMarkerUrl = data.value ? this.customMarkerUrl : null;
      this.markers = this.originalMarkers;
  }
  showTooltips() {
      this.markers = this.markers.map(function (item) {
          let newItem = JSON.parse(JSON.stringify(item));
          newItem.tooltip.isShown = true;
          return newItem;
      });
  }

  public getLatestData() {

    this.loading = true;

    this.mapservice.latestData().subscribe((res) => {
      this.latestData = res;

      console.log(JSON.stringify(this.latestData));
      const tempid = this.deviceStore.getSelectedDevice();


      console.log('Selected device:' + tempid.id);
      for (const temp of res) {
        if (temp.deviceId == tempid.id) {
          this.selectedDeviceData = temp;
        }
      }

      let tmpmarkers: Marker[] = [{
        location: [this.selectedDeviceData.latitude, this.selectedDeviceData.longitude],
        tooltip: {
            isShown: false,
            text: tempid.name + '<br>' + moment(this.selectedDeviceData.serverTime).format('YYYY-MM-DD hh:mm:ss')
        }
      }];

      this.originalMarkers = this.markers = tmpmarkers;

      this.loading = false;
      // this.dataSource.data = this.history;
      console.log('Selected device data:' + JSON.stringify(this.selectedDeviceData));
    });
  }

  ngOnInit() {
  }

}
