import { Injectable } from '@angular/core';
import { StoreService } from '../store.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserStoreService {

  constructor(private store: StoreService) { }

  public getUser() {
    return this.store.getStore('user');
  }

  public setUser(data: any) {
    this.store.setStore('user', data);
  }

  public saverUserAuth(user: any) {
    if (user && user.email && user.password) {
      const headers = new HttpHeaders({
        Authorization: 'Basic ' + btoa(user.email + ':' + user.password)
      });
      localStorage.setItem('alertbeewebauth', btoa(user.email + ':' + user.password));
      this.store.setStore('headers', headers);
    }
  }

  public getHeaders() {
    const headers = this.store.getStore('headers');
    if (!headers) {
      const auth = localStorage.getItem('alertbeewebauth');
      this.store.setStore('headers', new HttpHeaders({
        Authorization: 'Basic ' +  auth
      }));
    }
    return this.store.getStore('headers');
  }
}
