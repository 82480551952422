import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MasterComponent } from './master/master.component';

const routes: Routes = [
  {
    path: '',
    component: MasterComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      {
        path: 'dashboard',
        loadChildren:
          '../dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'user',
        loadChildren:
          '../user/user.module#UserModule',
      },
      {
        path: 'history',
        loadChildren:
          '../history/history.module#HistoryModule',
      },
      {
        path: 'map',
        loadChildren:
          '../map/map.module#MapModule',
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MasterRoutingModule { }
