import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotLoggedUserGuardServiceService implements CanLoad {
  constructor(private router: Router) {}

  canLoad() {
    const value = sessionStorage.getItem('uuidalertbee');
    if (value) {
      setTimeout(() => {
        this.router.navigate(['dashboard']);
      }, 100);
      return false;
    } else {
      return true;
    }
  }
}
