import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DeviceStoreService } from 'src/app/store/devices/device-store.service';
import { HistoryService, Weight } from '../history.service';
import { ToastrService } from 'ngx-toastr';
import { Moment } from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';


export interface HistoryElement {
  deviceTime: string;
  latitude: number;
  longitude: number;
  altitude: string;
  power: string;
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  providers: [HistoryService]
})
export class HistoryComponent implements OnInit {
  searchForm: FormGroup;
  history: Array<any> = [];
  totalHistoryItems = 0;
  weightData: Weight[] = [];
  selected: {
    startDate: Moment,
    endDate: Moment};
  displayedColumns: string[] = ['deviceTime', 'latitude', 'longitude', 'altitude', 'power'];
  dataSource = new MatTableDataSource<HistoryElement>(this.history);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  public loading = false;
  showWeight1= false;
  showWeight2= false;
  showWeight3= false;

  constructor(
    private formBuilder: FormBuilder,
    private deviceStore: DeviceStoreService,
    private historyService: HistoryService,
    private toastr: ToastrService
  ) {
    this.searchForm = this.formBuilder.group({
      from: ['', Validators.required],
      to: ['', Validators.required]
    });

  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  public isDateValid() {
    const {startDate, endDate} = this.selected;
    return startDate && endDate;
  }

  public search() {
    this.totalHistoryItems = 0;
    this.weightData=[];
    let {startDate, endDate} = this.selected;
    const selectedDevice = this.deviceStore.getSelectedDevice();
    if (!selectedDevice) {
      this.toastr.error('No device selected!', '', {
        positionClass: 'toast-bottom-right',
      });
    } else {
      this.loading = true;

      if(startDate.isSame(endDate)) {
        endDate = endDate.add(24, 'hours');
      }
      const data = {
        from: startDate,
        to: endDate,
        device: selectedDevice.id
      };
      console.log(this.selected);

      this.historyService.graphData(data).subscribe((res) => {
        this.history = res;
        this.history.forEach((item, i) => {
          item.rowIndex = i;
        });
        console.log(this.history);
        this.totalHistoryItems = this.history.length;
        
        this.showWeight1=false;
        this.showWeight2=false;
        this.showWeight3=false;

        for (const temp of res) {
          const tempweight: Weight = {
            w1: 0,
            w2: 0,
            w3: 0,
            d1: 0,
            d2: 0,
            d3: 0,
            int: 0,
            ext: 0,
            humi: 0,
            date: null
          };

          tempweight.w1 = temp.attributes.w1 / 1000;
          tempweight.w2 = temp.attributes.w2 / 1000;
          tempweight.w3 = temp.attributes.w3 / 1000;
          tempweight.d1 = temp.attributes.d1;
          tempweight.d2 = temp.attributes.d2;
          tempweight.d3 = temp.attributes.d3;
          tempweight.int = temp.attributes.inte;
          tempweight.ext = temp.attributes.exte;
          tempweight.humi = parseFloat((temp.attributes.humidity*10).toFixed(2));
          tempweight.date = new Date(temp.deviceTime);
          this.weightData.push(tempweight);

          if (tempweight.int>200)
          tempweight.int=null;
          if (tempweight.ext>200)
          tempweight.ext=null;
          
           //for show/hide of weights (if at least one weigh is > than zero then show weight)
           if(tempweight.w1>0)
           this.showWeight1=true;
         if(tempweight.w2>0)
           this.showWeight2=true;
         if(tempweight.w3>0)
           this.showWeight3=true;
        }


        this.loading = false;
        // this.dataSource.data = this.history;
        console.log(this.weightData);
      });

      this.historyService.search(data).subscribe((res: Array<any>) => {
        this.history = res;
        this.history.forEach((item, i) => {
          item.rowIndex = i;
        });
        console.log(this.history);
        this.totalHistoryItems = this.history.length;
        this.loading = false;
        this.dataSource.data = this.history;
        console.log(this.dataSource);
      });
    }
  }

  customizeTooltip(arg: any) {
      return arg.valueText;
  }

  
  legendClickHandler (e) {
    var series = e.target;
    if (series.isVisible()) {
        series.hide();
    } else {
        series.show();
    }
}
}
