import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxLoadingModule } from 'ngx-loading';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { HistoryRoutingModule } from './history-routing.module';
import { HistoryComponent } from './history/history.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {MatTableModule} from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

import { DxDataGridModule,DxChartModule, DxSelectBoxModule } from 'devextreme-angular';

@NgModule({
  declarations: [HistoryComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HistoryRoutingModule,
    NgxDaterangepickerMd.forRoot(),
    NgxLoadingModule.forRoot({}),
    PaginationModule,
    MatTableModule,
    MatPaginatorModule,
    DxChartModule,
    DxSelectBoxModule,
    DxDataGridModule
  ]
})
export class HistoryModule { }
