import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserStoreService } from 'src/app/store/dispatch/user.store.service';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeviceStoreService } from 'src/app/store/devices/device-store.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  public loading = false;
  devices: any = [];
  user: any = {};
  userForm: FormGroup;
  constructor(
    private userStore: UserStoreService,
    private userService: UserService,
    private toastr: ToastrService,
    private router: Router,
    private formBuilder: FormBuilder,
    private deviceStore: DeviceStoreService
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(3)]],
      name: ['', [Validators.required, Validators.minLength(3)]],
      phone: [''],
      password: ['', [Validators.required, Validators.minLength(3)]],
    });
    this.loadUser();
    this.loadDevices();
  }

  private async loadDevices() {
    this.devices = await this.deviceStore.getDevices();
  }

  private loadUser() {
    const user = this.userStore.getUser();
    if (!user) {
      const id = sessionStorage.getItem('uuidalertbee');
      if (id) {
        this.loading = true;
        this.userService.loadUser(Number(id)).subscribe(
          (result: any) => {
            this.setUserForm(result[0]);
            this.loading = false;
          },
          (err: any) => {
            this.toastr.error(err.error, '', {
              positionClass: 'toast-bottom-right'
            });
            this.router.navigate(['dashboard']);
            this.loading = false;
          }
        );
      }
    } else {
      this.setUserForm(user);
    }
  }

  public async updateProfile() {
    const {value, valid} = this.userForm;
    if (valid) {
      this.loading = true;
      try {
        const res = await this.userService.updateUser(value).toPromise();
        this.loading = false;
        if (res) {
          this.userStore.setUser(res);
          this.toastr.success('User updated', '', {
            positionClass: 'toast-bottom-right'
          });
        }
      } catch (err) {
        this.loading = false;
        this.toastr.error(err.error, '', {
          positionClass: 'toast-bottom-right'
        });
      }
    }
    console.log(value);
  }

  private setUserForm(user: any) {
    this.userForm.controls.email.setValue(user.email);
    this.userForm.controls.name.setValue(user.name);
    this.userForm.controls.phone.setValue(user.phone);
    this.userForm.controls.password.setValue(user.password);
  }
}
