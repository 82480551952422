import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceHeaderListComponent } from './device-header-list/device-header-list.component';
import { AlertHeaderListComponent } from './alert-header-list/alert-header-list.component';

@NgModule({
  declarations: [DeviceHeaderListComponent, AlertHeaderListComponent],
  imports: [
    CommonModule
  ],
  exports: [DeviceHeaderListComponent, AlertHeaderListComponent]
})
export class SharedModule { }
