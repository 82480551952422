import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StoreService } from 'src/app/store/store.service';

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit {
  @ViewChild('sidebar')
  sidebar!: ElementRef;
  @ViewChild('toggleNavBar')
  toggleNavBar!: ElementRef;
  visibleSidebar:string = "";
  constructor(private router: Router, private store: StoreService, renderer:Renderer2) {
renderer.listen('window', 'click', (e: Event) => {
      if (
        e.target === this.sidebar.nativeElement ||
        (e.target === this.toggleNavBar.nativeElement || e.target["parentElement"] === this.toggleNavBar.nativeElement)
      )
        this.visibleSidebar = 'visible';
      else this.visibleSidebar = '';
    });
   }

  ngOnInit() {
  }

  isRouteActive(url: string) {
    return this.router.url === url;
  }

  public signout() {
    this.store.clear();
    sessionStorage.clear();
    this.router.navigate(['auth', 'login']);
  }

}
