import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { UserStoreService } from '../store/dispatch/user.store.service';
import { BaseApiService } from '../base-api.service';
import { BehaviorSubject, Observable, from, of } from 'rxjs';

export class Tooltip {
  isShown: boolean;
  text: string;
}

export class Marker {
  location: any;
  tooltip: Tooltip;
}

export interface MyPosition {
  id: number;
  deviceId: number;
  protocol: string;
  deviceTime: Date;
  fixTime: Date;
  serverTime: Date;
  outdated: boolean;
  valid: boolean;
  latitude: number;
  longitude: number;
  altitude: number;
  speed: number;
  course: number;
  address: string;
  accuracy: number;
  network: string;
  attributes: Attributes;
}

export interface Attributes {
  battery: number;
  batteryLevel: number;
  d1: number;
  d2: number;
  d3: number;
  distance: number;
  exte: number;
  fe: number;
  humidity: number;
  inte: number;
  motion: boolean;
  totalDistance: number;
  w1: number;
  w2: number;
  w3: number;
  date: Date;
}


let markerUrl = "https://mz.alertbee.gr/assets/img/map-marker.png";
//let markerUrl = "https://js.devexpress.com/Demos/RealtorApp/images/map-marker.png";

let markers: Marker[] = [{
  location: "0,0",
  tooltip: {
      isShown: false,
      text: " "
  }
}];
@Injectable({
  providedIn: 'root'
})
export class MapService  extends BaseApiService {

  constructor(private userStore: UserStoreService, private http: HttpClient) { super();}

    getMarkerUrl() : string {
        return markerUrl;
    }
    getMarkers() : Marker[] {
        return markers;
    }

    public latestData(): Observable<MyPosition[]> {
      const headers = this.userStore.getHeaders();
      return this.http.get<MyPosition[]>(`${this.API_URL}/positions`, { headers }).pipe();
    }

}
